<template>
  <section>
    <div class="d-flex flex-column flex-md-row align-items-center align-content-center justify-content-md-start">
      <customer-avatar :customer="customer" size="10rem"></customer-avatar>
      <div class="d-flex flex-column ml-md-5 mt-3 mt-md-0">
        <h1 class="text-center text-md-left display-4">{{ customer.name }}</h1>
        <h6 class="text-center text-md-left">
          {{ customer.mobile }}
        </h6>
        <div class="my-3 text-center text-large">
          <b-badge v-if="customer.isLead" variant="success">Lead</b-badge>
          <b-badge v-if="customer.isPartner" variant="danger">Partner</b-badge>
          <b-badge v-if="customer.isCustomer" variant="primary">Customer</b-badge>
        </div>
        <div

            class="d-flex justify-content-center justify-content-md-start">
          <div class="btn-group">
            <b-button
                v-if="customer.isCustomer"
                variant="primary" :to="{'name': 'customer-detail', 'params': {'id': customerId}}">
              <font-awesome-icon icon="eye"></font-awesome-icon>
              Details
            </b-button>
            <b-button variant="secondary"
                      v-if="customer.isCustomer"
                      @click="newOrder">
              <font-awesome-icon icon="plus"></font-awesome-icon>
              New Order
            </b-button>

            <b-button variant="info"
                      v-if="customer.isLead || customer.mobile === '9591876072'|| customer.mobile === '8971753725'"
                      @click="sendOffer">
              <font-awesome-icon icon="money-check"></font-awesome-icon>
              Send Offer
            </b-button>

          </div>
        </div>
      </div>
    </div>

    <div class="mt-3 mb-3" v-if="order && customer.isCustomer">
      <h5 class="text-center">Last Order</h5>
      <order-display :order="order" :hide-customer="true"
                     v-on:order-updated="loadCustomerLatestOrder"></order-display>
    </div>

    <message-display :messages="messages" :customerId="customerId"
                     v-on:message-updated="loadMessages"></message-display>

    <b-modal id="send-offer" :ok-disabled="!brandId"
             @ok="sendActualOffer"
             :title="offerTitle">
      <b-form-group label="Select Brand">
        <b-form-select
            :options="$store.state.brands"
            v-model="brandId"
            @change="loadOffer"
            value-field="id"
            text-field="name"
        ></b-form-select>
      </b-form-group>
      <b-form-group label="Message">
        <b-form-input v-model="wm.message" readonly></b-form-input>
      </b-form-group>
    </b-modal>
  </section>
</template>
<script>
import axios from 'axios'
import MessageDisplay from '@/components/message-display'
import CustomerAvatar from './customer-avatar'
import OrderDisplay from './order-display'
import { NewDeliveryArea, NewOrder } from '../util'

export default {
  name: 'AdminMessagesDetail',
  components: {OrderDisplay, CustomerAvatar, MessageDisplay},
  data: () => ({
    customer: {name: '', isLead: false, isPartner: false, isCustomer: false, mobile: '', profilePicUrl: ''},
    messages: [],
    order: NewOrder(NewDeliveryArea(), null),
    brandId: null,
    wm: {}
  }),
  async created () {
    await this.loadCustomer()
    await this.loadCustomerLatestOrder()
    await this.loadMessages()
  },
  watch: {
    async orderUpdated (id) {
      console.log(`order with id ${id} is updated`)
      await this.loadCustomerLatestOrder()
    }
  },
  computed: {
    orderUpdated () {
      return this.$store.state.orderUpdated
    },
    customerId: function () {
      return this.$route.params.id
    },
    offerTitle () {
      return `Send Offer to Customer ${this.customer.name}`
    }
  },
  methods: {
    async newOrder () {
      await this.$store.dispatch(
          'createOrder',
          NewOrder(NewDeliveryArea(this.customer.deliveryArea.id), this.customer.brand.id, {id: this.customerId})
      )
    },
    async loadOffer () {
      setTimeout(async () => {
        this.wm = (await axios.get(`/api/super-admin/welcome?brandId=${this.brandId}`)).data
      }, 100)
    },
    async sendActualOffer () {
      await axios.post(`/api/super-admin/offer/send/${this.customer.mobile}?brandId=${this.brandId}`)
    },
    async sendOffer () {
      //await axios.post(`/api/super-admin/offer/send/${this.customerId}`)
      this.$bvModal.show('send-offer')
    },
    async loadCustomer () {
      let json = await axios.get(`/api/admin/customers/${this.customerId}`)
      this.customer = json.data
    },
    async loadCustomerLatestOrder () {
      if (this.customer.isCustomer) {
        try {
          let json = await axios.get(`/api/admin/orders/customer/last?customerId=${this.customerId}`)
          this.order = json.data
        } catch (e) {
          this.order = null
        }
      }
    },
    async loadMessages () {
      await this.$store.dispatch('loadThreads')
      let json = await axios.get(`/api/admin/message/customer/${this.customerId}`)
      this.messages = json.data
    }
  }
}
</script>