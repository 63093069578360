<template>
  <div class="list-group-item d-flex p-1 mt-1 border-bottom-0" v-if="source.customer">
    <div class="d-flex flex-fill" @click="loadThread()">
      <customer-avatar :customer="source.customer" size="3rem"></customer-avatar>
      <div class="d-flex flex-fill">
        <div class="ml-2 d-flex flex-column">
          <span>
            {{ source.customer.name }}
          </span>
          <span style="font-size: x-small">{{ humanize(source.last) }}</span>
          <div class="w-100">
            <b-badge variant="primary" v-if="!source.isLead && !source.isPartner">Customer</b-badge>
            <b-badge variant="success" v-if="source.isLead">Lead</b-badge>
            <b-badge variant="danger" v-if="source.isPartner">Partner</b-badge>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column">
      <div class="text-right pb-3" style="font-size: x-small">
        <span class="badge-pill badge-dark p-2" v-if="source.unread > 0">{{ source.unread }}</span>
        <span class="badge-pill p-2" v-else>&nbsp;</span>
      </div>
      <b-button size="sm" variant="outline-primary" v-if="!dnd" @click="addToDND">
        <font-awesome-icon :icon="['far', 'bell']"></font-awesome-icon>
      </b-button>
      <b-button size="sm" variant="outline-danger" v-else @click="removeFromDND">
        <font-awesome-icon :icon="['far', 'bell-slash']"></font-awesome-icon>
      </b-button>
    </div>
  </div>
  <div class="list-group-item d-flex flex-column p-1 mt-1" v-else>
    Customer Missing
  </div>
</template>
<script>
import CustomerAvatar from './customer-avatar'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import parseISO from 'date-fns/parseISO'
import axios from 'axios'

export default {
  name: 'MessageThread',
  components: {CustomerAvatar},
  props: {
    source: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data() {
    return {
      dnd: this.source.dnd
    }
  },
  methods: {
    humanize (at) {
      return formatDistanceToNowStrict(parseISO(at))
    },

    async removeFromDND () {
      await axios.put(`/api/super-admin/dnd?id=${this.source.customer.mobile}&dnd=false`)
      this.dnd = false
      await this.$store.dispatch('toggleDnD', {id: this.source.id, dnd: false})
    },
    async addToDND () {
      await axios.put(`/api/super-admin/dnd?id=${this.source.customer.mobile}&dnd=true`)
      this.dnd = true
      await this.$store.dispatch('toggleDnD', {id: this.source.id, dnd: true})
    },
    async loadThread(){
      let t = this.source
      let id = t.customer.id || t.customer.mobile
      console.log('load Thread for ', t.customer.id, t.customer.mobile, id)
      if (window.matchMedia('(max-width: 767px)').matches) {
        // Mobile
        await this.$router.push(`/admin/messages/${id}`)

      } else {
        // Desktop
        await this.$store.dispatch('loadSelectedCustomerMessage', id)
      }

    },
  }
}
</script>