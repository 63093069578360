<template>
  <section>
    <div class="d-flex flex-column flex-md-row align-items-center justify-content-md-between justify-content-center am-list">
      <h1 class="display-4">Messages</h1>
      <b-form-group label="What to Show?">
        <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="selected"
            :options="options"
            name="flavour-1"
            :size="chkBoxSize"
            switches
        ></b-form-checkbox-group>
      </b-form-group>
    </div>

    <div class="row">
      <div class="col-12 col-md-4 col-lg-3">
        <div class="list-group list-group-flush">
          <div class="list-group-item">
            <b-form-input v-model="searchCust" placeholder="Search Name/Mobile" debounce="500"></b-form-input>
          </div>

        </div>
        <virtual-list style="height: 90vh; overflow-y: auto;"
                      wrap-class="list-group list-group-flush"
                      item-class="list-group-item d-flex flex-column p-1 mt-1"
                      :data-key="'id'"
                      :data-sources="filteredThreads"
                      :data-component="itemComponent"
                      :estimate-size="60">
        </virtual-list>
      </div>
      <div class="col-12 col-md-8 col-lg-9 ">
        <div class="w-100 contents d-none d-md-inline-flex" id="contents-msg">
          <div v-if="customerId" class=" pl-3 ml-3 pb-3 mr-3 pr-3 pt-3 d-flex flex-column w-100">
            <div class="d-flex pb-3  align-content-center align-items-center">
              <customer-avatar :customer="selectedCustomer" size="5rem"></customer-avatar>
              <div class="ml-3 d-flex flex-column">
                <span class="display-7">{{ selectedCustomer.name }}</span>
                <a :href="'tel:+91'+selectedCustomer.mobile">
                  <font-awesome-icon icon="phone"></font-awesome-icon>
                  {{ selectedCustomer.mobile }}
                </a>
                <h4 class="mb-3">
                  <b-badge v-if="selectedCustomer.isLead" variant="success">Lead</b-badge>
                  <b-badge v-if="selectedCustomer.isPartner" variant="danger">Partner</b-badge>
                  <b-badge v-if="selectedCustomer.isCustomer" variant="primary">Customer</b-badge>
                </h4>
              </div>

              <div class="d-flex justify-content-end flex-fill align-content-center align-items-center">
                <div>
                  <b-btn-group size="sm">
                    <b-button variant="info"
                              v-if="selectedCustomer.id && selectedCustomer.id.length > 10"
                              :to="{'name': 'customer-detail', 'params': {'id': customerId}}">
                      <font-awesome-icon icon="eye"></font-awesome-icon>
                      Details
                    </b-button>
                    <b-button variant="secondary"
                              v-if="selectedCustomer.id && selectedCustomer.id.length > 10"
                    @click="newOrder(selectedCustomer)">
                      <font-awesome-icon icon="plus"></font-awesome-icon>
                      New Order
                    </b-button>
                    <b-button variant="secondary"
                              @click="newCustomer(selectedCustomer)"
                              v-else>
                      <font-awesome-icon icon="plus"></font-awesome-icon>
                      New Customer
                    </b-button>

                    <b-button variant="info"
                              v-if="selectedCustomer.isLead || selectedCustomer.mobile === '9591876072'"
                              @click="sendOffer">
                      <font-awesome-icon icon="money-check"></font-awesome-icon>
                      Send Offer
                    </b-button>
                  </b-btn-group>
                </div>
              </div>
            </div>

            <message-display :messages="messages"
                             :customerId="customerId"
                             v-on:message-updated="loadCustomerMessage"></message-display>
          </div>
          <div v-else class="">Select a message</div>
        </div>
      </div>
    </div>
    <b-modal id="send-offer" :ok-disabled="!brandId" :title="offerTitle" @ok="sendActualOffer">
      <b-form-group label="Select Brand">
        <b-form-select
            :options="$store.state.brands"
            v-model="brandId"
            value-field="id"
            @change="loadOffer"
            text-field="name"
        ></b-form-select>
      </b-form-group>

      <b-form-group label="Message">
        <b-form-textarea max-rows="10" v-model="wm.message" readonly></b-form-textarea>
      </b-form-group>
    </b-modal>
  </section>
</template>
<script>
import parseISO from 'date-fns/parseISO'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import CustomerAvatar from './customer-avatar'
import MessageDisplay from './message-display'
import MessageThread from './message-thread'
import VirtualList from 'vue-virtual-scroll-list'
import axios from "axios";
import { NewOrder } from '@/util'

export default {
  name: 'AdminMessages',
  components: {CustomerAvatar, MessageDisplay, VirtualList},
  data: () => ({
    selected: ['LEAD', 'CUSTOMER', 'PARTNER'],
    searchCust: '',
    itemComponent: MessageThread,
    brandId: null,
    options: [
      {text: 'Lead', value: 'LEAD'},
      {text: 'Customer', value: 'CUSTOMER'},
      {text: 'Partner', value: 'PARTNER'},
    ],
    wm: {}
  }),
  async created() {
    await this.$store.dispatch('loadThreads')
  },
  computed: {

    offerTitle() {
      return `Send Offer to Customer ${this.selectedCustomer.name}`
    },
    chkBoxSize() {
      if (window.matchMedia('(max-width: 567px)').matches) {
        // Mobile
        return 'lg'

      } else {
        // Desktop
        return 'lg'
      }
    },
    customerId() {
      return this.$store.state.msgScreen.customerId
    },
    messages() {
      return this.$store.state.msgScreen.messages
    },
    selectedCustomer() {
      return this.$store.state.msgScreen.selectedCustomer
    },
    filteredThreadsType() {
      return this.$store.state.threads.filter(f => {
        if (f.isLead) {
          return this.selected.indexOf('LEAD') > -1
        }
        if (f.isPartner) {
          return this.selected.indexOf('PARTNER') > -1
        }

        return this.selected.indexOf('CUSTOMER') > -1
      })
    },
    filteredThreads() {
      if (this.searchCust === '') {
        return this.filteredThreadsType
      } else {
        return this.filteredThreadsType.filter(f => {
          return f.customer.name.toLowerCase().indexOf(this.searchCust.toLowerCase()) > -1 ||
              f.customer.mobile.toLowerCase().indexOf(this.searchCust.toLowerCase()) > -1
        })
      }
    }
  },
  methods: {

    async newOrder(c){
      let addresses = (await axios.get(`/api/admin/address?customer_id=${c.id}`)).data
      let address = addresses[0].id
      await this.$store.dispatch('createOrder', new NewOrder(c.deliveryArea || {}, c.brand.id, {id: c.id}, {id: address, name: addresses[0].name}))
    },
    async newCustomer(sc){
      await this.$store.dispatch('editCustomer', JSON.stringify({id: 'NEW', mobile: sc.id, at: new Date().getTime(), brand: sc.brand}))
    },
    async loadOffer(){
      setTimeout(async () => {
        this.wm = (await axios.get(`/api/super-admin/welcome?brandId=${this.brandId}`)).data
      }, 100)
    },
    async sendActualOffer(){
      await axios.post(`/api/super-admin/offer/send/${this.selectedCustomer.mobile}?brandId=${this.brandId}`)
    },
    async sendOffer(){
      //await axios.post(`/api/super-admin/offer/send/${this.customerId}`)
      this.$bvModal.show('send-offer')
    },
    humanize(at) {
      return formatDistanceToNowStrict(parseISO(at))
    },
    async loadSidePanel(id) {
      this.customerId = id
      await this.loadCustomerMessage()
    },
    async loadCustomerMessage() {
      await this.$store.dispatch('loadSelectedCustomerMessage', this.customerId)
      document.getElementById('contents-msg').scrollIntoView()
    },
  }
}
</script>
<style scoped>

</style>