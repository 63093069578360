<template id="store-orders">
    <section>
        <div class="d-flex justify-content-between flex-column flex-md-row">
            <h4 class="text-center">Orders</h4>
            <div class="d-flex justify-content-end">
              <b-form-group label="Days" class="mr-2">
                <b-form-select :options="[30, 60, 90, 180, 365]" v-model="days" @change="loadOrders"></b-form-select>
              </b-form-group>
              <div class="d-flex flex-column justify-content-center">
                <b-button-group size="sm">
                  <b-button size="sm" to="/store/new-order" variant="info">
                    <font-awesome-icon icon="plus"></font-awesome-icon> New Order
                  </b-button>
                  <b-button size="sm" @click="loadOrders" variant="primary">
                    <font-awesome-icon icon="sync"></font-awesome-icon> Refresh
                  </b-button>
                </b-button-group>
              </div>
            </div>
        </div>
        <div class="d-flex flex-column flex-md-row flex-wrap mt-2 mt-md-3">
            <order-display
                    v-for="order of orders"
                    :key="order.id"
                    :order="order"
                    v-on:edit-order="editOrder(order.id)"
                    v-on:order-updated="loadOrders"
            >
            </order-display>
        </div>
    </section>
</template>
<script>
    import axios from 'axios'
    import OrderDisplay from '@/components/order-display'

    export default {
        name: 'StoreOrders',
        components: {OrderDisplay},
        data: () => ({
            mobile: '',
            orders: [],
            days: 30
        }),
        async created() {
            await this.$store.dispatch('loadState')
            await this.loadOrders()
        },
        computed: {},
        methods: {
            //load orders based onn the login credentials,
            // if store employee show only for his store
            //if Admin show all store orders
            async loadOrders() {
                await this.$store.dispatch('loadState')
                let o = await axios(`/api/store/orders?days=${this.days}`)
                this.orders = o.data
            },
            async editOrder(id) {
                await this.$router.push(`/store/edit-order/${id}`)
            },
        }
    }
</script>
<style>
</style>