<template>
  <b-tabs>
    <b-tab title="Custom Flour Config">
      <table class="table">
        <thead>
        <tr>
          <th>Category</th>
          <th>Max GM/KG</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="c of cfg" :key="c.materialType">
          <td>{{c.materialType}}</td>
          <td>
            <b-form-input v-model="c.maxGmPerKG" type="number" @change="save(c)"></b-form-input>
          </td>
        </tr>
        </tbody>
      </table>
    </b-tab>
  </b-tabs>
</template>
<script>
    import axios from 'axios'

    export default {
        name: 'AdminConfig',
        data: () => ({
            cfg: []
        }),
        async created() {
            let resp = await axios('/api/admin/cfg/material-config')
            this.cfg = resp.data
        },
        methods: {
            async save(w) {
                await axios.post('/api/admin/cfg/material-config', w)
            }
        }
    }
</script>